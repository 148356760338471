// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-history-js": () => import("./../../../src/pages/about-us/history.js" /* webpackChunkName: "component---src-pages-about-us-history-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-our-structure-js": () => import("./../../../src/pages/about-us/our-structure.js" /* webpackChunkName: "component---src-pages-about-us-our-structure-js" */),
  "component---src-pages-allied-clinical-department-js": () => import("./../../../src/pages/allied-clinical-department.js" /* webpackChunkName: "component---src-pages-allied-clinical-department-js" */),
  "component---src-pages-allied-departments-nutrition-diatetics-js": () => import("./../../../src/pages/allied-departments/nutrition-diatetics.js" /* webpackChunkName: "component---src-pages-allied-departments-nutrition-diatetics-js" */),
  "component---src-pages-allied-departments-pharmacy-js": () => import("./../../../src/pages/allied-departments/pharmacy.js" /* webpackChunkName: "component---src-pages-allied-departments-pharmacy-js" */),
  "component---src-pages-allied-departments-physiotherapy-js": () => import("./../../../src/pages/allied-departments/physiotherapy.js" /* webpackChunkName: "component---src-pages-allied-departments-physiotherapy-js" */),
  "component---src-pages-ans-policy-js": () => import("./../../../src/pages/ans-policy.js" /* webpackChunkName: "component---src-pages-ans-policy-js" */),
  "component---src-pages-become-teacher-js": () => import("./../../../src/pages/become-teacher.js" /* webpackChunkName: "component---src-pages-become-teacher-js" */),
  "component---src-pages-bio-data-ana-js": () => import("./../../../src/pages/bio-data/ana.js" /* webpackChunkName: "component---src-pages-bio-data-ana-js" */),
  "component---src-pages-bio-data-bio-med-js": () => import("./../../../src/pages/bio-data/bio-med.js" /* webpackChunkName: "component---src-pages-bio-data-bio-med-js" */),
  "component---src-pages-bio-data-comm-health-js": () => import("./../../../src/pages/bio-data/comm-health.js" /* webpackChunkName: "component---src-pages-bio-data-comm-health-js" */),
  "component---src-pages-bio-data-him-js": () => import("./../../../src/pages/bio-data/him.js" /* webpackChunkName: "component---src-pages-bio-data-him-js" */),
  "component---src-pages-bio-data-med-psy-js": () => import("./../../../src/pages/bio-data/med-psy.js" /* webpackChunkName: "component---src-pages-bio-data-med-psy-js" */),
  "component---src-pages-bio-data-midwifery-js": () => import("./../../../src/pages/bio-data/midwifery.js" /* webpackChunkName: "component---src-pages-bio-data-midwifery-js" */),
  "component---src-pages-bio-data-nursing-js": () => import("./../../../src/pages/bio-data/nursing.js" /* webpackChunkName: "component---src-pages-bio-data-nursing-js" */),
  "component---src-pages-bio-data-pbn-js": () => import("./../../../src/pages/bio-data/pbn.js" /* webpackChunkName: "component---src-pages-bio-data-pbn-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-course-accident-emergency-js": () => import("./../../../src/pages/course/accident-emergency.js" /* webpackChunkName: "component---src-pages-course-accident-emergency-js" */),
  "component---src-pages-course-anaesthetic-js": () => import("./../../../src/pages/course/anaesthetic.js" /* webpackChunkName: "component---src-pages-course-anaesthetic-js" */),
  "component---src-pages-course-bio-med-hnd-js": () => import("./../../../src/pages/course/bio-med-hnd.js" /* webpackChunkName: "component---src-pages-course-bio-med-hnd-js" */),
  "component---src-pages-course-bio-med-nd-js": () => import("./../../../src/pages/course/bio-med-nd.js" /* webpackChunkName: "component---src-pages-course-bio-med-nd-js" */),
  "component---src-pages-course-comm-health-js": () => import("./../../../src/pages/course/comm-health.js" /* webpackChunkName: "component---src-pages-course-comm-health-js" */),
  "component---src-pages-course-details-js": () => import("./../../../src/pages/course-details.js" /* webpackChunkName: "component---src-pages-course-details-js" */),
  "component---src-pages-course-health-info-hnd-js": () => import("./../../../src/pages/course/health-info-hnd.js" /* webpackChunkName: "component---src-pages-course-health-info-hnd-js" */),
  "component---src-pages-course-health-info-nd-js": () => import("./../../../src/pages/course/health-info-nd.js" /* webpackChunkName: "component---src-pages-course-health-info-nd-js" */),
  "component---src-pages-course-med-psy-js": () => import("./../../../src/pages/course/med-psy.js" /* webpackChunkName: "component---src-pages-course-med-psy-js" */),
  "component---src-pages-course-midwifery-js": () => import("./../../../src/pages/course/midwifery.js" /* webpackChunkName: "component---src-pages-course-midwifery-js" */),
  "component---src-pages-course-nursing-js": () => import("./../../../src/pages/course/nursing.js" /* webpackChunkName: "component---src-pages-course-nursing-js" */),
  "component---src-pages-course-ophthalmic-js": () => import("./../../../src/pages/course/ophthalmic.js" /* webpackChunkName: "component---src-pages-course-ophthalmic-js" */),
  "component---src-pages-course-paediatric-js": () => import("./../../../src/pages/course/paediatric.js" /* webpackChunkName: "component---src-pages-course-paediatric-js" */),
  "component---src-pages-course-perioperative-js": () => import("./../../../src/pages/course/perioperative.js" /* webpackChunkName: "component---src-pages-course-perioperative-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-departments-anaesthesia-js": () => import("./../../../src/pages/departments/anaesthesia.js" /* webpackChunkName: "component---src-pages-departments-anaesthesia-js" */),
  "component---src-pages-departments-anatomic-path-js": () => import("./../../../src/pages/departments/anatomic-path.js" /* webpackChunkName: "component---src-pages-departments-anatomic-path-js" */),
  "component---src-pages-departments-child-dental-js": () => import("./../../../src/pages/departments/child-dental.js" /* webpackChunkName: "component---src-pages-departments-child-dental-js" */),
  "component---src-pages-departments-clinical-path-js": () => import("./../../../src/pages/departments/clinical-path.js" /* webpackChunkName: "component---src-pages-departments-clinical-path-js" */),
  "component---src-pages-departments-comm-health-js": () => import("./../../../src/pages/departments/comm-health.js" /* webpackChunkName: "component---src-pages-departments-comm-health-js" */),
  "component---src-pages-departments-ent-js": () => import("./../../../src/pages/departments/ent.js" /* webpackChunkName: "component---src-pages-departments-ent-js" */),
  "component---src-pages-departments-family-medicine-js": () => import("./../../../src/pages/departments/family-medicine.js" /* webpackChunkName: "component---src-pages-departments-family-medicine-js" */),
  "component---src-pages-departments-haematology-js": () => import("./../../../src/pages/departments/haematology.js" /* webpackChunkName: "component---src-pages-departments-haematology-js" */),
  "component---src-pages-departments-js": () => import("./../../../src/pages/departments.js" /* webpackChunkName: "component---src-pages-departments-js" */),
  "component---src-pages-departments-medicine-js": () => import("./../../../src/pages/departments/medicine.js" /* webpackChunkName: "component---src-pages-departments-medicine-js" */),
  "component---src-pages-departments-microbio-js": () => import("./../../../src/pages/departments/microbio.js" /* webpackChunkName: "component---src-pages-departments-microbio-js" */),
  "component---src-pages-departments-nursing-services-js": () => import("./../../../src/pages/departments/nursing-services.js" /* webpackChunkName: "component---src-pages-departments-nursing-services-js" */),
  "component---src-pages-departments-obstetrics-js": () => import("./../../../src/pages/departments/obstetrics.js" /* webpackChunkName: "component---src-pages-departments-obstetrics-js" */),
  "component---src-pages-departments-ophthalmology-js": () => import("./../../../src/pages/departments/ophthalmology.js" /* webpackChunkName: "component---src-pages-departments-ophthalmology-js" */),
  "component---src-pages-departments-oral-path-js": () => import("./../../../src/pages/departments/oral-path.js" /* webpackChunkName: "component---src-pages-departments-oral-path-js" */),
  "component---src-pages-departments-oral-surgery-js": () => import("./../../../src/pages/departments/oral-surgery.js" /* webpackChunkName: "component---src-pages-departments-oral-surgery-js" */),
  "component---src-pages-departments-orthopaedics-js": () => import("./../../../src/pages/departments/orthopaedics.js" /* webpackChunkName: "component---src-pages-departments-orthopaedics-js" */),
  "component---src-pages-departments-paediatrics-js": () => import("./../../../src/pages/departments/paediatrics.js" /* webpackChunkName: "component---src-pages-departments-paediatrics-js" */),
  "component---src-pages-departments-preventive-dentistry-js": () => import("./../../../src/pages/departments/preventive-dentistry.js" /* webpackChunkName: "component---src-pages-departments-preventive-dentistry-js" */),
  "component---src-pages-departments-psychiatry-js": () => import("./../../../src/pages/departments/psychiatry.js" /* webpackChunkName: "component---src-pages-departments-psychiatry-js" */),
  "component---src-pages-departments-radiation-js": () => import("./../../../src/pages/departments/radiation.js" /* webpackChunkName: "component---src-pages-departments-radiation-js" */),
  "component---src-pages-departments-radiodiagnosis-js": () => import("./../../../src/pages/departments/radiodiagnosis.js" /* webpackChunkName: "component---src-pages-departments-radiodiagnosis-js" */),
  "component---src-pages-departments-restorative-dentistry-js": () => import("./../../../src/pages/departments/restorative-dentistry.js" /* webpackChunkName: "component---src-pages-departments-restorative-dentistry-js" */),
  "component---src-pages-departments-surgery-js": () => import("./../../../src/pages/departments/surgery.js" /* webpackChunkName: "component---src-pages-departments-surgery-js" */),
  "component---src-pages-events-2016-cheveron-donates-lab-js": () => import("./../../../src/pages/events/2016/cheveron-donates.lab.js" /* webpackChunkName: "component---src-pages-events-2016-cheveron-donates-lab-js" */),
  "component---src-pages-events-2016-graduation-biomedical-engineering-js": () => import("./../../../src/pages/events/2016/graduation-biomedical-engineering.js" /* webpackChunkName: "component---src-pages-events-2016-graduation-biomedical-engineering-js" */),
  "component---src-pages-events-2016-index-js": () => import("./../../../src/pages/events/2016/index.js" /* webpackChunkName: "component---src-pages-events-2016-index-js" */),
  "component---src-pages-events-2016-laparoscopic-day-js": () => import("./../../../src/pages/events/2016/laparoscopic-day.js" /* webpackChunkName: "component---src-pages-events-2016-laparoscopic-day-js" */),
  "component---src-pages-events-2016-smile-train-js": () => import("./../../../src/pages/events/2016/smile-train.js" /* webpackChunkName: "component---src-pages-events-2016-smile-train-js" */),
  "component---src-pages-events-2017-glaucoma-week-js": () => import("./../../../src/pages/events/2017/glaucoma-week.js" /* webpackChunkName: "component---src-pages-events-2017-glaucoma-week-js" */),
  "component---src-pages-events-2017-groundround-2017-js": () => import("./../../../src/pages/events/2017/groundround-2017.js" /* webpackChunkName: "component---src-pages-events-2017-groundround-2017-js" */),
  "component---src-pages-events-2017-him-matric-2017-js": () => import("./../../../src/pages/events/2017/him-matric-2017.js" /* webpackChunkName: "component---src-pages-events-2017-him-matric-2017-js" */),
  "component---src-pages-events-2017-index-js": () => import("./../../../src/pages/events/2017/index.js" /* webpackChunkName: "component---src-pages-events-2017-index-js" */),
  "component---src-pages-events-2017-mls-executive-2017-js": () => import("./../../../src/pages/events/2017/mls-executive-2017.js" /* webpackChunkName: "component---src-pages-events-2017-mls-executive-2017-js" */),
  "component---src-pages-events-2017-modular-theatre-js": () => import("./../../../src/pages/events/2017/modular-theatre.js" /* webpackChunkName: "component---src-pages-events-2017-modular-theatre-js" */),
  "component---src-pages-events-2017-new-resident-doctor-js": () => import("./../../../src/pages/events/2017/new-resident-doctor.js" /* webpackChunkName: "component---src-pages-events-2017-new-resident-doctor-js" */),
  "component---src-pages-events-2017-open-heart-surgery-js": () => import("./../../../src/pages/events/2017/open-heart-surgery.js" /* webpackChunkName: "component---src-pages-events-2017-open-heart-surgery-js" */),
  "component---src-pages-events-2017-peadiatric-epidurals-training-js": () => import("./../../../src/pages/events/2017/peadiatric-epidurals-training.js" /* webpackChunkName: "component---src-pages-events-2017-peadiatric-epidurals-training-js" */),
  "component---src-pages-events-2017-sbdn-health-week-2017-js": () => import("./../../../src/pages/events/2017/sbdn-health-week-2017.js" /* webpackChunkName: "component---src-pages-events-2017-sbdn-health-week-2017-js" */),
  "component---src-pages-events-2017-vp-visit-luth-js": () => import("./../../../src/pages/events/2017/vp-visit-luth.js" /* webpackChunkName: "component---src-pages-events-2017-vp-visit-luth-js" */),
  "component---src-pages-events-2017-world-health-day-2017-js": () => import("./../../../src/pages/events/2017/world-health-day-2017.js" /* webpackChunkName: "component---src-pages-events-2017-world-health-day-2017-js" */),
  "component---src-pages-events-2017-worshop-new-resident-doctors-js": () => import("./../../../src/pages/events/2017/worshop-new-resident-doctors.js" /* webpackChunkName: "component---src-pages-events-2017-worshop-new-resident-doctors-js" */),
  "component---src-pages-events-2018-index-js": () => import("./../../../src/pages/events/2018/index.js" /* webpackChunkName: "component---src-pages-events-2018-index-js" */),
  "component---src-pages-events-2018-management-retreat-js": () => import("./../../../src/pages/events/2018/management-retreat.js" /* webpackChunkName: "component---src-pages-events-2018-management-retreat-js" */),
  "component---src-pages-events-2019-index-js": () => import("./../../../src/pages/events/2019/index.js" /* webpackChunkName: "component---src-pages-events-2019-index-js" */),
  "component---src-pages-events-2019-inner-wheel-js": () => import("./../../../src/pages/events/2019/inner-wheel.js" /* webpackChunkName: "component---src-pages-events-2019-inner-wheel-js" */),
  "component---src-pages-events-2019-luth-week-js": () => import("./../../../src/pages/events/2019/luth-week.js" /* webpackChunkName: "component---src-pages-events-2019-luth-week-js" */),
  "component---src-pages-events-2019-nsia-cancer-centre-js": () => import("./../../../src/pages/events/2019/nsia-cancer-centre.js" /* webpackChunkName: "component---src-pages-events-2019-nsia-cancer-centre-js" */),
  "component---src-pages-events-2019-smile-train-project-js": () => import("./../../../src/pages/events/2019/smile-train-project.js" /* webpackChunkName: "component---src-pages-events-2019-smile-train-project-js" */),
  "component---src-pages-events-2019-spbn-disaster-program-js": () => import("./../../../src/pages/events/2019/spbn-disaster-program.js" /* webpackChunkName: "component---src-pages-events-2019-spbn-disaster-program-js" */),
  "component---src-pages-events-2019-us-ambassador-visit-js": () => import("./../../../src/pages/events/2019/us-ambassador-visit.js" /* webpackChunkName: "component---src-pages-events-2019-us-ambassador-visit-js" */),
  "component---src-pages-events-2020-index-js": () => import("./../../../src/pages/events/2020/index.js" /* webpackChunkName: "component---src-pages-events-2020-index-js" */),
  "component---src-pages-events-2020-luth-day-js": () => import("./../../../src/pages/events/2020/luth-day.js" /* webpackChunkName: "component---src-pages-events-2020-luth-day-js" */),
  "component---src-pages-events-2021-index-js": () => import("./../../../src/pages/events/2021/index.js" /* webpackChunkName: "component---src-pages-events-2021-index-js" */),
  "component---src-pages-events-2021-olusola-dada-js": () => import("./../../../src/pages/events/2021/olusola-dada.js" /* webpackChunkName: "component---src-pages-events-2021-olusola-dada-js" */),
  "component---src-pages-events-2021-picture-roll-js": () => import("./../../../src/pages/events/2021/picture-roll.js" /* webpackChunkName: "component---src-pages-events-2021-picture-roll-js" */),
  "component---src-pages-events-2022-blood-donor-clinic-js": () => import("./../../../src/pages/events/2022/blood-donor-clinic.js" /* webpackChunkName: "component---src-pages-events-2022-blood-donor-clinic-js" */),
  "component---src-pages-events-2022-commission-outpatient-centre-js": () => import("./../../../src/pages/events/2022/commission-outpatient-centre.js" /* webpackChunkName: "component---src-pages-events-2022-commission-outpatient-centre-js" */),
  "component---src-pages-events-2022-first-bank-donates-to-luth-js": () => import("./../../../src/pages/events/2022/first-bank-donates-to-luth.js" /* webpackChunkName: "component---src-pages-events-2022-first-bank-donates-to-luth-js" */),
  "component---src-pages-events-2022-hon-comm-visit-to-luth-js": () => import("./../../../src/pages/events/2022/hon-comm-visit-to-luth.js" /* webpackChunkName: "component---src-pages-events-2022-hon-comm-visit-to-luth-js" */),
  "component---src-pages-events-2022-js": () => import("./../../../src/pages/events/2022.js" /* webpackChunkName: "component---src-pages-events-2022-js" */),
  "component---src-pages-events-2022-luth-mgmt-board-nsia-js": () => import("./../../../src/pages/events/2022/luth-mgmt-board-nsia.js" /* webpackChunkName: "component---src-pages-events-2022-luth-mgmt-board-nsia-js" */),
  "component---src-pages-events-2022-mabo-foundation-visit-js": () => import("./../../../src/pages/events/2022/mabo-foundation-visit.js" /* webpackChunkName: "component---src-pages-events-2022-mabo-foundation-visit-js" */),
  "component---src-pages-events-2022-wgo-training-js": () => import("./../../../src/pages/events/2022/wgo-training.js" /* webpackChunkName: "component---src-pages-events-2022-wgo-training-js" */),
  "component---src-pages-events-2022-world-oral-health-day-js": () => import("./../../../src/pages/events/2022/world-oral-health-day.js" /* webpackChunkName: "component---src-pages-events-2022-world-oral-health-day-js" */),
  "component---src-pages-events-2023-breast-feeding-js": () => import("./../../../src/pages/events/2023/breast-feeding.js" /* webpackChunkName: "component---src-pages-events-2023-breast-feeding-js" */),
  "component---src-pages-events-2023-capping-and-strapping-ceremony-js": () => import("./../../../src/pages/events/2023/capping-and-strapping-ceremony.js" /* webpackChunkName: "component---src-pages-events-2023-capping-and-strapping-ceremony-js" */),
  "component---src-pages-events-2023-hospital-grand-march-2023-js": () => import("./../../../src/pages/events/2023/hospital-grand-march-2023.js" /* webpackChunkName: "component---src-pages-events-2023-hospital-grand-march-2023-js" */),
  "component---src-pages-events-2023-index-js": () => import("./../../../src/pages/events/2023/index.js" /* webpackChunkName: "component---src-pages-events-2023-index-js" */),
  "component---src-pages-events-2023-luth-icwc-2023-day-1-js": () => import("./../../../src/pages/events/2023/luth-icwc-2023-day1.js" /* webpackChunkName: "component---src-pages-events-2023-luth-icwc-2023-day-1-js" */),
  "component---src-pages-events-2023-luth-icwc-2023-day-2-js": () => import("./../../../src/pages/events/2023/luth-icwc-2023-day2.js" /* webpackChunkName: "component---src-pages-events-2023-luth-icwc-2023-day-2-js" */),
  "component---src-pages-events-2023-matrick-js": () => import("./../../../src/pages/events/2023/matrick.js" /* webpackChunkName: "component---src-pages-events-2023-matrick-js" */),
  "component---src-pages-events-2023-nlng-upgrades-bed-js": () => import("./../../../src/pages/events/2023/nlng-upgrades-bed.js" /* webpackChunkName: "component---src-pages-events-2023-nlng-upgrades-bed-js" */),
  "component---src-pages-events-2023-workshop-icwc-js": () => import("./../../../src/pages/events/2023/workshop-icwc.js" /* webpackChunkName: "component---src-pages-events-2023-workshop-icwc-js" */),
  "component---src-pages-events-2024-index-js": () => import("./../../../src/pages/events/2024/index.js" /* webpackChunkName: "component---src-pages-events-2024-index-js" */),
  "component---src-pages-events-2024-luth-ground-round-jan-js": () => import("./../../../src/pages/events/2024/luth-ground-round-jan.js" /* webpackChunkName: "component---src-pages-events-2024-luth-ground-round-jan-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-luthatsisty-images-js": () => import("./../../../src/pages/events/luthatsisty/images.js" /* webpackChunkName: "component---src-pages-events-luthatsisty-images-js" */),
  "component---src-pages-events-luthsixty-js": () => import("./../../../src/pages/events/luthsixty.js" /* webpackChunkName: "component---src-pages-events-luthsixty-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-grants-js": () => import("./../../../src/pages/grants.js" /* webpackChunkName: "component---src-pages-grants-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inprogress-departments-anaesthesia-js": () => import("./../../../src/pages/inprogress/departments/anaesthesia.js" /* webpackChunkName: "component---src-pages-inprogress-departments-anaesthesia-js" */),
  "component---src-pages-inprogress-departments-anatomic-path-js": () => import("./../../../src/pages/inprogress/departments/anatomic-path.js" /* webpackChunkName: "component---src-pages-inprogress-departments-anatomic-path-js" */),
  "component---src-pages-inprogress-departments-child-dental-js": () => import("./../../../src/pages/inprogress/departments/child-dental.js" /* webpackChunkName: "component---src-pages-inprogress-departments-child-dental-js" */),
  "component---src-pages-inprogress-departments-clinical-path-js": () => import("./../../../src/pages/inprogress/departments/clinical-path.js" /* webpackChunkName: "component---src-pages-inprogress-departments-clinical-path-js" */),
  "component---src-pages-inprogress-departments-comm-health-js": () => import("./../../../src/pages/inprogress/departments/comm-health.js" /* webpackChunkName: "component---src-pages-inprogress-departments-comm-health-js" */),
  "component---src-pages-inprogress-departments-ent-js": () => import("./../../../src/pages/inprogress/departments/ent.js" /* webpackChunkName: "component---src-pages-inprogress-departments-ent-js" */),
  "component---src-pages-inprogress-departments-family-medicine-js": () => import("./../../../src/pages/inprogress/departments/family-medicine.js" /* webpackChunkName: "component---src-pages-inprogress-departments-family-medicine-js" */),
  "component---src-pages-inprogress-departments-haematology-js": () => import("./../../../src/pages/inprogress/departments/haematology.js" /* webpackChunkName: "component---src-pages-inprogress-departments-haematology-js" */),
  "component---src-pages-inprogress-departments-js": () => import("./../../../src/pages/inprogress/departments.js" /* webpackChunkName: "component---src-pages-inprogress-departments-js" */),
  "component---src-pages-inprogress-departments-medicine-js": () => import("./../../../src/pages/inprogress/departments/medicine.js" /* webpackChunkName: "component---src-pages-inprogress-departments-medicine-js" */),
  "component---src-pages-inprogress-departments-microbio-js": () => import("./../../../src/pages/inprogress/departments/microbio.js" /* webpackChunkName: "component---src-pages-inprogress-departments-microbio-js" */),
  "component---src-pages-inprogress-departments-obstetrics-js": () => import("./../../../src/pages/inprogress/departments/obstetrics.js" /* webpackChunkName: "component---src-pages-inprogress-departments-obstetrics-js" */),
  "component---src-pages-inprogress-departments-ophthalmology-js": () => import("./../../../src/pages/inprogress/departments/ophthalmology.js" /* webpackChunkName: "component---src-pages-inprogress-departments-ophthalmology-js" */),
  "component---src-pages-inprogress-departments-oral-path-js": () => import("./../../../src/pages/inprogress/departments/oral-path.js" /* webpackChunkName: "component---src-pages-inprogress-departments-oral-path-js" */),
  "component---src-pages-inprogress-departments-oral-surgery-js": () => import("./../../../src/pages/inprogress/departments/oral-surgery.js" /* webpackChunkName: "component---src-pages-inprogress-departments-oral-surgery-js" */),
  "component---src-pages-inprogress-departments-orthopaedics-js": () => import("./../../../src/pages/inprogress/departments/orthopaedics.js" /* webpackChunkName: "component---src-pages-inprogress-departments-orthopaedics-js" */),
  "component---src-pages-inprogress-departments-paediatrics-js": () => import("./../../../src/pages/inprogress/departments/paediatrics.js" /* webpackChunkName: "component---src-pages-inprogress-departments-paediatrics-js" */),
  "component---src-pages-inprogress-departments-preventive-dentistry-js": () => import("./../../../src/pages/inprogress/departments/preventive-dentistry.js" /* webpackChunkName: "component---src-pages-inprogress-departments-preventive-dentistry-js" */),
  "component---src-pages-inprogress-departments-psychiatry-js": () => import("./../../../src/pages/inprogress/departments/psychiatry.js" /* webpackChunkName: "component---src-pages-inprogress-departments-psychiatry-js" */),
  "component---src-pages-inprogress-departments-radiation-js": () => import("./../../../src/pages/inprogress/departments/radiation.js" /* webpackChunkName: "component---src-pages-inprogress-departments-radiation-js" */),
  "component---src-pages-inprogress-departments-radiodiagnosis-js": () => import("./../../../src/pages/inprogress/departments/radiodiagnosis.js" /* webpackChunkName: "component---src-pages-inprogress-departments-radiodiagnosis-js" */),
  "component---src-pages-inprogress-departments-restorative-dentistry-js": () => import("./../../../src/pages/inprogress/departments/restorative-dentistry.js" /* webpackChunkName: "component---src-pages-inprogress-departments-restorative-dentistry-js" */),
  "component---src-pages-inprogress-departments-surgery-js": () => import("./../../../src/pages/inprogress/departments/surgery.js" /* webpackChunkName: "component---src-pages-inprogress-departments-surgery-js" */),
  "component---src-pages-inprogress-index-js": () => import("./../../../src/pages/inprogress/index.js" /* webpackChunkName: "component---src-pages-inprogress-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-manuscript-check-status-js": () => import("./../../../src/pages/manuscript/check-status.js" /* webpackChunkName: "component---src-pages-manuscript-check-status-js" */),
  "component---src-pages-manuscript-evidence-upload-js": () => import("./../../../src/pages/manuscript/evidence-upload.js" /* webpackChunkName: "component---src-pages-manuscript-evidence-upload-js" */),
  "component---src-pages-manuscript-manuscriptapply-js": () => import("./../../../src/pages/manuscript/manuscriptapply.js" /* webpackChunkName: "component---src-pages-manuscript-manuscriptapply-js" */),
  "component---src-pages-manuscript-replace-file-manu-js": () => import("./../../../src/pages/manuscript/replace-file-manu.js" /* webpackChunkName: "component---src-pages-manuscript-replace-file-manu-js" */),
  "component---src-pages-news-4th-annual-conference-js": () => import("./../../../src/pages/news/4th-annual-conference.js" /* webpackChunkName: "component---src-pages-news-4th-annual-conference-js" */),
  "component---src-pages-news-application-extension-js": () => import("./../../../src/pages/news/application-extension.js" /* webpackChunkName: "component---src-pages-news-application-extension-js" */),
  "component---src-pages-news-application-js": () => import("./../../../src/pages/news/application.js" /* webpackChunkName: "component---src-pages-news-application-js" */),
  "component---src-pages-news-birthday-js": () => import("./../../../src/pages/news/birthday.js" /* webpackChunkName: "component---src-pages-news-birthday-js" */),
  "component---src-pages-news-bone-marrow-transplant-js": () => import("./../../../src/pages/news/bone-marrow-transplant.js" /* webpackChunkName: "component---src-pages-news-bone-marrow-transplant-js" */),
  "component---src-pages-news-breast-cancer-awareness-24-js": () => import("./../../../src/pages/news/breast-cancer-awareness-24.js" /* webpackChunkName: "component---src-pages-news-breast-cancer-awareness-24-js" */),
  "component---src-pages-news-breast-feeding-news-js": () => import("./../../../src/pages/news/breast-feeding-news.js" /* webpackChunkName: "component---src-pages-news-breast-feeding-news-js" */),
  "component---src-pages-news-capital-projects-execution-js": () => import("./../../../src/pages/news/capital-projects-execution.js" /* webpackChunkName: "component---src-pages-news-capital-projects-execution-js" */),
  "component---src-pages-news-cmac-js": () => import("./../../../src/pages/news/cmac.js" /* webpackChunkName: "component---src-pages-news-cmac-js" */),
  "component---src-pages-news-cmd-transform-js": () => import("./../../../src/pages/news/cmd-transform.js" /* webpackChunkName: "component---src-pages-news-cmd-transform-js" */),
  "component---src-pages-news-comm-health-medical-outreach-js": () => import("./../../../src/pages/news/comm-health-medical-outreach.js" /* webpackChunkName: "component---src-pages-news-comm-health-medical-outreach-js" */),
  "component---src-pages-news-dental-children-js": () => import("./../../../src/pages/news/dental-children.js" /* webpackChunkName: "component---src-pages-news-dental-children-js" */),
  "component---src-pages-news-details-js": () => import("./../../../src/pages/news-details.js" /* webpackChunkName: "component---src-pages-news-details-js" */),
  "component---src-pages-news-directorofadmin-js": () => import("./../../../src/pages/news/directorofadmin.js" /* webpackChunkName: "component---src-pages-news-directorofadmin-js" */),
  "component---src-pages-news-disclaimer-js": () => import("./../../../src/pages/news/disclaimer.js" /* webpackChunkName: "component---src-pages-news-disclaimer-js" */),
  "component---src-pages-news-exam-date-nursing-js": () => import("./../../../src/pages/news/exam-date-nursing.js" /* webpackChunkName: "component---src-pages-news-exam-date-nursing-js" */),
  "component---src-pages-news-federal-seminar-js": () => import("./../../../src/pages/news/federal-seminar.js" /* webpackChunkName: "component---src-pages-news-federal-seminar-js" */),
  "component---src-pages-news-ground-round-diabetis-melitus-js": () => import("./../../../src/pages/news/ground-round-diabetis-melitus.js" /* webpackChunkName: "component---src-pages-news-ground-round-diabetis-melitus-js" */),
  "component---src-pages-news-head-of-service-js": () => import("./../../../src/pages/news/head-of-service.js" /* webpackChunkName: "component---src-pages-news-head-of-service-js" */),
  "component---src-pages-news-icwc-2023-js": () => import("./../../../src/pages/news/icwc-2023.js" /* webpackChunkName: "component---src-pages-news-icwc-2023-js" */),
  "component---src-pages-news-independence-js": () => import("./../../../src/pages/news/independence.js" /* webpackChunkName: "component---src-pages-news-independence-js" */),
  "component---src-pages-news-international-scientific-conference-js": () => import("./../../../src/pages/news/international-scientific-conference.js" /* webpackChunkName: "component---src-pages-news-international-scientific-conference-js" */),
  "component---src-pages-news-invite-scientific-conference-js": () => import("./../../../src/pages/news/invite-scientific-conference.js" /* webpackChunkName: "component---src-pages-news-invite-scientific-conference-js" */),
  "component---src-pages-news-irredeemable-js": () => import("./../../../src/pages/news/irredeemable.js" /* webpackChunkName: "component---src-pages-news-irredeemable-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-lagoon-partners-js": () => import("./../../../src/pages/news/lagoon-partners.js" /* webpackChunkName: "component---src-pages-news-lagoon-partners-js" */),
  "component---src-pages-news-luth-brain-drain-js": () => import("./../../../src/pages/news/luth-brain-drain.js" /* webpackChunkName: "component---src-pages-news-luth-brain-drain-js" */),
  "component---src-pages-news-luth-gets-new-cmd-js": () => import("./../../../src/pages/news/luth-gets-new-cmd.js" /* webpackChunkName: "component---src-pages-news-luth-gets-new-cmd-js" */),
  "component---src-pages-news-luth-ground-round-jan-24-news-js": () => import("./../../../src/pages/news/luth-ground-round-jan24-news.js" /* webpackChunkName: "component---src-pages-news-luth-ground-round-jan-24-news-js" */),
  "component---src-pages-news-luth-pamela-js": () => import("./../../../src/pages/news/luth-pamela.js" /* webpackChunkName: "component---src-pages-news-luth-pamela-js" */),
  "component---src-pages-news-luth-surgery-oesophagus-js": () => import("./../../../src/pages/news/luth-surgery-oesophagus.js" /* webpackChunkName: "component---src-pages-news-luth-surgery-oesophagus-js" */),
  "component---src-pages-news-marks-years-six-project-js": () => import("./../../../src/pages/news/marks-years-six-project.js" /* webpackChunkName: "component---src-pages-news-marks-years-six-project-js" */),
  "component---src-pages-news-maryland-luth-js": () => import("./../../../src/pages/news/maryland-luth.js" /* webpackChunkName: "component---src-pages-news-maryland-luth-js" */),
  "component---src-pages-news-nass-js": () => import("./../../../src/pages/news/nass.js" /* webpackChunkName: "component---src-pages-news-nass-js" */),
  "component---src-pages-news-nlng-upgrades-bed-js": () => import("./../../../src/pages/news/nlng-upgrades-bed.js" /* webpackChunkName: "component---src-pages-news-nlng-upgrades-bed-js" */),
  "component---src-pages-news-nurse-ndidi-retire-js": () => import("./../../../src/pages/news/nurse-ndidi-retire.js" /* webpackChunkName: "component---src-pages-news-nurse-ndidi-retire-js" */),
  "component---src-pages-news-nursing-director-retire-js": () => import("./../../../src/pages/news/nursing-director-retire.js" /* webpackChunkName: "component---src-pages-news-nursing-director-retire-js" */),
  "component---src-pages-news-oncology-workshop-js": () => import("./../../../src/pages/news/oncology-workshop.js" /* webpackChunkName: "component---src-pages-news-oncology-workshop-js" */),
  "component---src-pages-news-postbasic-schools-app-js": () => import("./../../../src/pages/news/postbasic-schools-app.js" /* webpackChunkName: "component---src-pages-news-postbasic-schools-app-js" */),
  "component---src-pages-news-pressrelease-js": () => import("./../../../src/pages/news/pressrelease.js" /* webpackChunkName: "component---src-pages-news-pressrelease-js" */),
  "component---src-pages-news-program-update-js": () => import("./../../../src/pages/news/program-update.js" /* webpackChunkName: "component---src-pages-news-program-update-js" */),
  "component---src-pages-news-school-app-open-js": () => import("./../../../src/pages/news/school-app-open.js" /* webpackChunkName: "component---src-pages-news-school-app-open-js" */),
  "component---src-pages-news-senate-committee-commends-mgt-js": () => import("./../../../src/pages/news/senate-committee-commends-mgt.js" /* webpackChunkName: "component---src-pages-news-senate-committee-commends-mgt-js" */),
  "component---src-pages-news-sickle-cell-js": () => import("./../../../src/pages/news/sickle-cell.js" /* webpackChunkName: "component---src-pages-news-sickle-cell-js" */),
  "component---src-pages-news-valecdictory-lecture-js": () => import("./../../../src/pages/news/valecdictory-lecture.js" /* webpackChunkName: "component---src-pages-news-valecdictory-lecture-js" */),
  "component---src-pages-news-wgo-training-js": () => import("./../../../src/pages/news/wgo-training.js" /* webpackChunkName: "component---src-pages-news-wgo-training-js" */),
  "component---src-pages-news-world-patient-safety-js": () => import("./../../../src/pages/news/world-patient-safety.js" /* webpackChunkName: "component---src-pages-news-world-patient-safety-js" */),
  "component---src-pages-newsarchives-2023-js": () => import("./../../../src/pages/newsarchives2023.js" /* webpackChunkName: "component---src-pages-newsarchives-2023-js" */),
  "component---src-pages-newsarchives-js": () => import("./../../../src/pages/newsarchives.js" /* webpackChunkName: "component---src-pages-newsarchives-js" */),
  "component---src-pages-non-clinical-departments-js": () => import("./../../../src/pages/non-clinical-departments.js" /* webpackChunkName: "component---src-pages-non-clinical-departments-js" */),
  "component---src-pages-non-departments-servicom-js": () => import("./../../../src/pages/non-departments/servicom.js" /* webpackChunkName: "component---src-pages-non-departments-servicom-js" */),
  "component---src-pages-past-research-js": () => import("./../../../src/pages/past-research.js" /* webpackChunkName: "component---src-pages-past-research-js" */),
  "component---src-pages-patient-guide-client-access-js": () => import("./../../../src/pages/patient-guide/ClientAccess.js" /* webpackChunkName: "component---src-pages-patient-guide-client-access-js" */),
  "component---src-pages-patient-guide-clinics-js": () => import("./../../../src/pages/patient-guide/clinics.js" /* webpackChunkName: "component---src-pages-patient-guide-clinics-js" */),
  "component---src-pages-patient-guide-complaints-servicom-charter-js": () => import("./../../../src/pages/patient-guide/complaints-servicom-charter.js" /* webpackChunkName: "component---src-pages-patient-guide-complaints-servicom-charter-js" */),
  "component---src-pages-patient-guide-complaints-servicom-js": () => import("./../../../src/pages/patient-guide/complaints-servicom.js" /* webpackChunkName: "component---src-pages-patient-guide-complaints-servicom-js" */),
  "component---src-pages-patient-guide-health-info-js": () => import("./../../../src/pages/patient-guide/health-info.js" /* webpackChunkName: "component---src-pages-patient-guide-health-info-js" */),
  "component---src-pages-patient-guide-patient-testimonials-js": () => import("./../../../src/pages/patient-guide/patient-testimonials.js" /* webpackChunkName: "component---src-pages-patient-guide-patient-testimonials-js" */),
  "component---src-pages-patient-guide-world-leprosy-js": () => import("./../../../src/pages/patient-guide/world-leprosy.js" /* webpackChunkName: "component---src-pages-patient-guide-world-leprosy-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-research-diabetis-melitus-js": () => import("./../../../src/pages/research/diabetis-melitus.js" /* webpackChunkName: "component---src-pages-research-diabetis-melitus-js" */),
  "component---src-pages-research-elegibilitycriteria-js": () => import("./../../../src/pages/research/elegibilitycriteria.js" /* webpackChunkName: "component---src-pages-research-elegibilitycriteria-js" */),
  "component---src-pages-research-get-health-info-js": () => import("./../../../src/pages/research/get-health.info.js" /* webpackChunkName: "component---src-pages-research-get-health-info-js" */),
  "component---src-pages-research-guidelines-antibiopsy-js": () => import("./../../../src/pages/research/guidelines-antibiopsy.js" /* webpackChunkName: "component---src-pages-research-guidelines-antibiopsy-js" */),
  "component---src-pages-research-hrec-js": () => import("./../../../src/pages/research/hrec.js" /* webpackChunkName: "component---src-pages-research-hrec-js" */),
  "component---src-pages-schools-anaesthetic-js": () => import("./../../../src/pages/schools/anaesthetic.js" /* webpackChunkName: "component---src-pages-schools-anaesthetic-js" */),
  "component---src-pages-schools-biomed-js": () => import("./../../../src/pages/schools/biomed.js" /* webpackChunkName: "component---src-pages-schools-biomed-js" */),
  "component---src-pages-schools-comm-health-js": () => import("./../../../src/pages/schools/comm-health.js" /* webpackChunkName: "component---src-pages-schools-comm-health-js" */),
  "component---src-pages-schools-health-info-js": () => import("./../../../src/pages/schools/health-info.js" /* webpackChunkName: "component---src-pages-schools-health-info-js" */),
  "component---src-pages-schools-index-js": () => import("./../../../src/pages/schools/index.js" /* webpackChunkName: "component---src-pages-schools-index-js" */),
  "component---src-pages-schools-list-js": () => import("./../../../src/pages/schools-list.js" /* webpackChunkName: "component---src-pages-schools-list-js" */),
  "component---src-pages-schools-med-psy-js": () => import("./../../../src/pages/schools/med-psy.js" /* webpackChunkName: "component---src-pages-schools-med-psy-js" */),
  "component---src-pages-schools-midwifery-js": () => import("./../../../src/pages/schools/midwifery.js" /* webpackChunkName: "component---src-pages-schools-midwifery-js" */),
  "component---src-pages-schools-nursing-js": () => import("./../../../src/pages/schools/nursing.js" /* webpackChunkName: "component---src-pages-schools-nursing-js" */),
  "component---src-pages-schools-post-basic-nursing-js": () => import("./../../../src/pages/schools/post-basic-nursing.js" /* webpackChunkName: "component---src-pages-schools-post-basic-nursing-js" */),
  "component---src-pages-teacher-details-js": () => import("./../../../src/pages/teacher-details.js" /* webpackChunkName: "component---src-pages-teacher-details-js" */),
  "component---src-pages-teachers-js": () => import("./../../../src/pages/teachers.js" /* webpackChunkName: "component---src-pages-teachers-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

